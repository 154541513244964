import React, {Component} from "react";
import {Jumbotron, Button} from "react-bootstrap";

export default class Homepage extends Component {
    render() {
        return (
            <div className='nav-shadow'>
                <Jumbotron className={'bg-dark mx-4 corner-cut'}>
                    <h1>All your development needs, one engineer.</h1>
                    <i>I'm an experienced developer who can work with a wide variety of technologies and programming languages.</i>
                    <br /><br />
                    Including but not limited to:
                    <ul>
                        <li>Web development (React, Node, Vanilla)</li>
                        <li>Machine learning, AI & Data science</li>
                        <li>Desktop apps</li>
                        <li>Integrated systems</li>
                    </ul>
                    <span className='tag-wrap'>
                    <Button
                        href='https://cbad.xyz'
                        target="_blank"
                        rel="noopener noreferrer"
                        variant='primary'
                        className={'mt-3 btn-square'}>
                        View a live<br/> tech demo
                    </Button>
                </span>
                </Jumbotron>
            </div>
        );
    }
}