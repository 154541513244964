import React, {Component} from "react";
import {Col, Row} from "react-bootstrap";
import {faEnvelope, faPhone, faScroll} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {faGithub, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import hiveLogo from "../img/hive-blockchain-logo.svg";

export default class Footer extends Component {
    render() {
        return(
            <footer className='bg-dark p-4 sticky-bottom corner-cut-tr'>
                <Row>
                    <Col sm={1}></Col>
                    <Col sm={4} className={'pb-4'}>
                        <h6>Contact and information</h6>
                        <ul>
                            <li>
                                <a href={'mailto:'+this.props.email}>
                                    <FontAwesomeIcon icon={faEnvelope} /> {this.props.email}
                                </a>
                            </li>
                            <li>
                                <a href="tel:+447393453206"><FontAwesomeIcon icon={faPhone} /> +44 7393 453206</a>
                            </li>
                            <li>
                                <a href='/docs/cv_17.pdf' download>
                                    <FontAwesomeIcon icon={faScroll} /> Download my CV (resumé)
                                </a>
                            </li>
                        </ul>
                    </Col>
                    <Col sm={3} className={'pb-4'}>
                        <h6>My profiles</h6>
                        <ul>
                            <li>
                                <a href="https://github.com/sisygoboom" target="_blank" rel="noreferrer noopener">
                                    <FontAwesomeIcon icon={faGithub} /> Github
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/in/christopher-graney-ward-530b53174/" target="_blank" rel="noreferrer noopener">
                                    <FontAwesomeIcon icon={faLinkedin} /> Linkedin
                                </a>
                            </li>
                            <li>
                                <a href="https://peakd.com/@sisygoboom" target="_blank" rel="noreferrer noopener">
                                    <img src={hiveLogo} alt='Hive logo' height={15} width={15} /> Hive
                                </a>
                            </li>
                        </ul>
                    </Col>
                    <Col sm={4}>
                        <h6>More Info</h6>
                        <p>
                            <Link to='/about'>
                                About this site
                            </Link>
                        </p>
                        <p className="text-light">
                            <sub>Site design © {new Date().getFullYear()} Christopher Graney-Ward</sub>
                        </p>
                    </Col>
                </Row>
            </footer>
        )
    }
}