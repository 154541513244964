import React, {Component} from "react";
import {Nav, Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";

export default class Navigation extends Component {
    render() {
        return(
            <Navbar sticky='top' collapseOnSelect expand="lg" bg="secondary" className='corner-cut-bl'>
                <Navbar.Brand>
                    <Link to="/">
                        Chris Graney-Ward
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id='responsive-navbar-nav'>
                    <Nav className='mr-auto'>
                        <Link to='/demo' className={'nav-link'}>
                            Tech demos
                        </Link>
                        <Link to='/contact' className={'nav-link'}>
                            Contact
                        </Link>
                        <Link to='/about' className={'nav-link'}>
                            This site
                        </Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}