import React, {lazy, Suspense} from 'react';
import {
  HashRouter,
  Switch,
  Route
} from 'react-router-dom';
import {Spinner} from "react-bootstrap";
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Homepage from "./views/Homepage";
import './custom.scss';
import './App.css';

const About = lazy(() => import('./views/About'));
const Contact = lazy(() => import('./views/Contact'));
const Demo = lazy(() => import('./views/Demo'));

const loading = <Spinner animation="grow" variant="light" />;

export class App extends React.PureComponent {
  render() {
    return (
        <div>
          <HashRouter>
            <div className='nav-shadow'>
              <Navigation />
            </div>
            <Switch>
              <Route path='/' exact>
                <header className="App-header">
                  <Homepage />
                </header>
              </Route>
              <Route path='/about'>
                <header className="App-header">
                  <Suspense fallback={loading}>
                    <About />
                  </Suspense>
                </header>
              </Route>
              <Route path='/contact'>
                <header className='App-header'>
                  <Suspense fallback={loading}>
                    <Contact />
                  </Suspense>
                </header>
              </Route>
              <Route path='/demo'>
                <Suspense fallback={loading}>
                  <Demo />
                </Suspense>
              </Route>
            </Switch>
            <div className='nav-shadow'>
              <Footer email={'chrisgraneyward@gmail.com'} />
            </div>
          </HashRouter>
        </div>
    );
  }
}

export default App;
